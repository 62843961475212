import React, { Component } from "react";
import "./Contact.scss";

class Contact extends Component {
  render() {
    return (
      <div className="footer">
          <div className="contact">
            <span>616.340.5865</span>
            <span>cristpeter@gmail.com</span>
            <span><a target="_blank" href="https://twitter.com/coffeefedcode" rel="noopener noreferrer">@coffeefedcode</a></span>
          </div>
      </div>
    );
  }
}
export default Contact;
