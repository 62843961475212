import React from 'react';
import './App.scss';
import Content from './components/Content';
import Contact from './components/Contact';

function App() {
  return (
    <div className="App">
      <div className='background' id="picture"></div>
      <Content />
      <Contact />
      <div className='background' id="layer2"></div>
      <div className='background' id="layer1"></div>
    </div>
  );
}

export default App;
