import React, { Component } from "react";
import "./Content.scss";
import ProfileIcon from "./icons/ProfileIcon";
import LinkedinIcon from "./icons/Linkedin";
import GithubIcon from "./icons/GithubIcon";
import Resume from "../assets/resume.pdf";

class Content extends Component {
  render() {
    return (
      <div className="container">
        <h1>Peter Crist</h1>
        <p>I design & build software.</p>
        <div className="link-section">
          <div className="links">
            <a target="_blank" href={Resume} rel="noopener noreferrer">
              <span>Resume <ProfileIcon className='icon'/></span>
            </a>
            <a target="_blank" href="https://linkedin.com/in/peter-crist" rel="noopener noreferrer">
              <span>Linkedin <LinkedinIcon className='icon' /></span>
            </a>
            <a target="_blank" href="https://github.com/peter-crist" rel="noopener noreferrer">
              <span>Github <GithubIcon className='icon' /></span>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
export default Content;
